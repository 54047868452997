interface StatusConfig {
  names: Record<string, string>;
  colors: Record<string, string>;
}

export const entityStatusConfig: Record<string, StatusConfig> = {
  taskInstances: {
    names: {
      PENDING: 'Pendiente',
      CANCELLED: 'Cancelada',
      FINISHED: 'Finalizada',
    },
    colors: {
      PENDING: 'bg-hg-black500',
      CANCELLED: 'bg-hg-error',
      FINISHED: 'bg-hg-green',
    },
  },
  budgets: {
    names: {
      OPEN: 'Pendiente',
      PAID: 'Pagado',
      REJECTED: 'Rechazado',
      CONTACTED: 'En seguimiento',
      EXPIRED: 'Expirado',
    },
    colors: {
      OPEN: 'text-hg-tertiary bg-hg-tertiary300',
      PAID: 'text-hg-green bg-hg-green100',
      REJECTED: 'text-hg-error bg-hg-error100',
      CONTACTED: 'text-hg-secondary bg-hg-secondary100',
      EXPIRED: 'text-hg-black500 bg-hg-black100',
    },
  },
};

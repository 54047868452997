export interface ProductClinics {
  clinic: Clinic;
}

export interface Clinic {
  id: string;
  city: string;
  address: string;
  internalName: string;
  professionals: Professional[];
  flowwwId: string;
  phone: string;
  addressExtraInfo: string;
  district?: string;
  province?: string;
  zipCode?: string;
  lat: number;
  long: number;
  autonomousRegion: string;
  order: number;
  parkingAddress: string;
  gmbNote: number;
  startDate: Date;
}
export interface ClinicReview {
  rating: number;
  comment: string;
  authorName: string;
  authorPicture: string;
  clinic: Clinic;
}

export interface Professional {
  description: string;
  id: string;
  collegiateNumber: string;
  title: string;
  tittleAbbreviation: string;
  urlPhoto: string;
  name: string;
  professionalType: ProfessionalType;
  flowwwId: string;
  authorDescription: string;
  city?: string;
  active: boolean;
  order: number;
  clinics?: Clinic[];
}

export enum ProfessionalType {
  All = 0,
  Medical = 1,
  BeautyAdvisor = 2,
  Others = 3,
  Marketing = 4,
  Admin = 5,
  CallAgent = 6,
  Assistant = 7,
  Receptionist = 8,
  ComputerReceptionist = 9,
}

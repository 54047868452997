import { User } from './appointment';
import { Product } from './product';

export interface PaymentTicketRequest {
  id: string;
  amount: number;
  method: PaymentMethod;
  bank: PaymentBank;
  paymentReference: string;
}
export interface ProductTicketRequest {
  id: string;
  price: string;
}

export interface PendingPayment {
  user: User;
  amount: number;
  referenceId: string;
  productPayment: {
    product: Product;
  }[];
}

export enum PaymentMethod {
  Cash,
  CreditCard,
  Financing,
  Others,
  Wallet,
  AdvancedPayment,
}

export enum PaymentBank {
  None,
  Alma,
  Pepper,
  Cash,
  Stripe,
  CreditCard,
  GooglePay,
  ApplePay,
  Paypal,
  Frakmenta,
  InstantCredit,
}

export interface PaymentInitResponse {
  id: string;
  url: string;
  referenceId: string;
  embeddedReference: string;
}

export const minimumAmounts: Record<PaymentBank, number> = {
  [PaymentBank.None]: 0,
  [PaymentBank.Alma]: 50,
  [PaymentBank.Pepper]: 50,
  [PaymentBank.Cash]: 0,
  [PaymentBank.Stripe]: 0,
  [PaymentBank.CreditCard]: 0,
  [PaymentBank.GooglePay]: 0,
  [PaymentBank.ApplePay]: 0,
  [PaymentBank.Paypal]: 0,
  [PaymentBank.Frakmenta]: 0,
  [PaymentBank.InstantCredit]: 200,
};

import { ApplicationOrigin } from 'app/stores/globalStore';
import { Product } from 'app/types/product';

export function getDiscountedPrice(
  product: Product,
  currentOrigin: ApplicationOrigin,
  clinic: string | undefined = undefined
) {
  let totalDiscountSum = 0;
  if (product && product.discounts && product.discounts.length > 0) {
    product.discounts.forEach(x => {
      let applyByClinic = false;
      if (
        clinic &&
        x.productDiscountClinics &&
        x.productDiscountClinics.length > 0
      ) {
        applyByClinic =
          x.productDiscountClinics.find(
            y => y.clinicInfo.id.toLowerCase() === clinic.toLowerCase()
          ) != undefined;
      } else if (
        !clinic &&
        x.productDiscountClinics &&
        x.productDiscountClinics.length > 0
      )
        applyByClinic = false;
      else applyByClinic = true;

      if (applyByClinic) {
        if (currentOrigin == ApplicationOrigin.Landing && x.visibleLanding)
          totalDiscountSum += x.totalDiscount;
        else if (
          currentOrigin == ApplicationOrigin.Dashboard &&
          x.visibleDashboard
        )
          totalDiscountSum += x.totalDiscount;
        else if (currentOrigin == ApplicationOrigin.Web && x.visibleWeb)
          totalDiscountSum += x.totalDiscount;
      }
    });
  }
  return totalDiscountSum === 0 ? null : product?.price - totalDiscountSum;
}
